import React , { useState , Fragment, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import LoadingBar from 'react-top-loading-bar'
import 'react-tabs/style/react-tabs.css';
import CardModal from '../CardModal';
import ReactGA from 'react-ga4';


const OpenMics = () => {
    const [dataTab] = useState(
        [
            {
                id: 0,
                title: "Sunday",
                shortened: "Sun."
            },
            {
                id: 1,
                title: "Monday",
                shortened: "Mon."
            },
            {
                id: 2,
                title: "Tuesday",
                shortened: "Tues."
            },
            {
                id: 3,
                title: "Wednesday",
                shortened: "Wed."
            },
            {
                id: 4,
                title: "Thursday",
                shortened: "Thurs."
            },
            {
                id: 5,
                title: "Friday",
                shortened: "Fri."
            },
            {
                id: 6,
                title: "Saturday",
                shortened: "Sat."
            }
        ]
    )
    const [progress,setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataPanel, setItems] = useState([
        {
            id: 0,
            idVal: "Sunday",
            dataContent: []
        },
        {
            id: 1,
            idVal: "Monday",
            dataContent: []
        },
        {
            id: 2,
            idVal: "Tuesday",
            dataContent: []
        },
        {
            id: 3,
            idVal: "Wednesday",
            dataContent: []
        },
        {
            id: 4,
            idVal: "Thursday",
            dataContent: []
        },
        {
            id: 5,
            idVal: "Friday",
            dataContent: []
        },
        {
            id: 6,
            idVal: "Saturday",
            dataContent: []
        }
                
    ]);
    var dayMap = 
    {
        "Sunday" : 0,
        "Monday" : 1,
        "Tuesday" : 2,
        "Wednesday" : 3,
        "Thursday" : 4,
        "Friday" : 5,
        "Saturday" : 6
    }

    useEffect(() => {
        setProgress(40)
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/1VH5sB5YJzYBzBQO-fIuAfsFEop-bsBOhMadhskXAoUg/values/Sheet1?key=AIzaSyDA-pldYo3BKr9eTp3FxN-ZIjSei3Uqa88`)
        .then(result=>result.json())
        .then((items)=>{
            setProgress(80)
            var keys = items.values[0];
            items.values.splice(0,1);
            var results = [];
            items.values.forEach((values, index) => {
                var newObj = {id: index};
                values.forEach((value, i) =>{
                    if(keys[i] == "dayOfWeek"){ 
                        newObj[keys[i]] = dayMap[value]
                    } else{
                         newObj[keys[i]] = value
                        }
                    }
                );
                results.push(newObj)
            }          
            )
            var weekDayArray = dataPanel;
            results.forEach((value,i) =>{
                weekDayArray[value.dayOfWeek].dataContent.push(value)
            })
            // console.log(results)
            // console.log(weekDayArray)
            setProgress(100)
            setItems(weekDayArray);
            setIsLoaded(true);
        },
        (error) => {
            setProgress(100)
            setIsLoaded(true);
            setError(error);
          }
    )
    ReactGA.send({ hitType: "pageview", page: "/open-mics" });
    }, [])

    const d = new Date();
    var day = d.getDay();
    
    const [tabIndex, setTabIndex] = useState(day);

    const [modalShow, setModalShow] = useState(false);
    if(isLoaded){
        return (
            <Fragment>
                <div className="tf-section sc-explore-2 open-mics">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="heading-live-auctions mg-bt-21">
                                <h2 className="tf-title">
                                    Open Mics
                                </h2>
                            </div>
                            <div className="heading-live-auctions mg-bt-21">
                                <h6>
                                    List is subject to change. Make sure to contact the venue day of the event to ensure the Open Mic is occuring. 
                                </h6>
                            </div>
                                <div className="flat-tabs explore-tab">
                                    <Tabs selectedIndex={tabIndex} onSelect={(index) => {setTabIndex(index); ReactGA.send({ hitType: "pageview", page: "/open-mics?day=" + day })}}>
                                        <TabList>
                                            {
                                                dataTab.map(data=> (
                                                    <Tab key={data.id} >{data.title}</Tab>
                                                ))
                                            }
                                        </TabList>
                                        {
                                            dataPanel.map(data =>(
                                                <TabPanel key={data.id}>
                                                    {
                                                        <section className="tf-section tf-rank tf-mics">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="table-ranking">
                                                                        <div className="flex th-title">
                                                                            <div className="column1">
                                                                                <h3>{dataTab[tabIndex].shortened} Mics</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                                <h3>Time</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                            <h3>List</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                            <h3>Address</h3>
                                                                            </div>
                                                                            <div className="column">
                                                                            <h3>Occurs</h3>
                                                                            </div>
                                                                            <div className="column notes-title">
                                                                            <h3>Notes</h3>
                                                                            </div>
    
                                                                        </div>
                                                                        {
                                                                            data.dataContent.map((item,index) => (
                                                                                <div key={item.id +"#"+ index} className="fl-item2">
                                                                                    <div className="item flex">
                                                                                        <div className="infor-item flex column1">
                                                                                            <div className="media">
                                                                                                <img 
                                                                                                src={item.image} 
                                                                                                alt="open mic image"
                                                                                                onError={event => {
                                                                                                    event.target.src = "../../../assets/images/mic-default.png"
                                                                                                    event.onerror = null
                                                                                                  }
                                                                                                }/>
                                                                                            </div>
                                                                                            <div className="content-collection pad-t-4">
                                                                                                <h5 className="title mb-15"><a href={item.link}>{item.name}</a></h5>
                                                                                                <div className="author flex">
                                                                                                    <div className="content">
                                                                                                        <p>Hosted By</p>
                                                                                                        <h6><a href={item.hostsSocialMedia || "#"}>{item.host}</a></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="column td1">
                                                                                            <span>{item.time}</span>
                                                                                        </div>
                                                                                        <div className="column td2">
                                                                                            <span>{item.list}</span>
                                                                                        </div>
                                                                                        <div className="column td3">
                                                                                        <a href={"https://maps.google.com/?q=" + item.address}>
                                                                                            <span>{item.address}</span>
                                                                                        </a>
                                                                                        </div>
                                                                                        <div className="column td4 occurs">
                                                                                            <span>{item.occurs}</span>
                                                                                        </div>
                                                                                        <div className="column td5 notes">
                                                                                            <span>{item.notes}</span>
                                                                                        </div>
                        
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        {
                                                                            data.dataContent.length == 0 &&
                                                                                <h5>Nothing this day</h5>
                                                                            
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                </TabPanel>
                                            ))
                                        }
                                    </Tabs>
                                </div> 
                            </div>   
                        </div>
                    </div>
                </div>
                <CardModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Fragment>
        );
    } else if(!isLoaded){
        return(
            <Fragment>
                <LoadingBar height={5} loaderSpeed={1000} color='#5142FC' progress={progress} onLoaderFinished={() => setProgress(0)} />
                <div className="tf-section sc-explore-2 open-mics">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                            <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        <div className="tf-section sc-explore-2 open-mics">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                    <h2>Error loading page. Try to Refresh.</h2>
                    </div>
                </div>
            </div>
        </div>
    }
    
}

export default OpenMics;
