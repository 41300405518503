import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import 'react-tabs/style/react-tabs.css';
import defaultImage from "../assets/images/person-icon.png"
import { format } from 'date-fns'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom'

const ShowDetails = () => {
    
    const location = useLocation()
    const { item } = location.state
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/show-details?" + item.name });
    })

    return (
        <div className='item-details'>
            <Header />
            <section className="flat-title-page inner comedy-show">
                <div className="overlay"></div>              
            </section>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img
                                    src={item.image}
                                    alt="show image"
                                    onError={event =>{
                                        event.target.src = "../assets/images/default-comedy-show.png"
                                        event.onerror = null
                                      }
                                    }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{item.name}</h2>
                                    <div className="meta-item">
                                        <div className="left">
                                            {/* <span className="viewed eye">225</span>
                                            <span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span> */}
                                        </div>
                                        <div className="right">
                                            {/* <Link to="#" className="share"></Link> */}
                                            {/* <Link to="#" className="option"></Link> */}
                                        </div>
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img 
                                                    src={item.hostImage || ""} 
                                                    alt="host"
                                                    onError={event =>{
                                                        event.target.src = defaultImage
                                                        event.onerror = null
                                                        }
                                                    }  
                                                    />
                                                </div>
                                                <a href={item.hostSocialMedia}>
                                                <div className="info">
                                                    <span>Hosted By</span>
                                                    <h6> {item.host} </h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img 
                                                    src={item.headlinerImage || ""} 
                                                    alt="headliner"
                                                    onError={event =>{
                                                    event.target.src = defaultImage
                                                    event.onerror = null
                                                    }
                                                    } />
                                                </div>
                                                <a href={item.headlinerSocialMedia}>
                                                <div className="info">
                                                    <span>Headliners:</span>
                                                    <h6>{item.headliner}</h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <a href={"https://maps.google.com/?q=" + item.address}>
                                                <div className="info">
                                                    <span>Address:</span>
                                                    <h6>{item.address}</h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="info">
                                                    <span>Ticket Price:</span>
                                                    <h5>{item.price == "0.00" ? "Free" : "$" + item.price}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <h6 className='center-text padding-y-10'>{item.notes}</h6>
                                    
                                    <div className="meta-item-details style2">
                                    <div className="item count-down">
                                            <span className="heading style-2">Date</span>
                                                <span>{item.date != "" ? format(new Date(item.date + " " + item.time), 'E, MMM d, yyyy') : "NA" }</span>
                                        </div>
                                        <div className="item meta-price">
                                            <span className="heading">Showtime</span>
                                            <div className="price">
                                                <div className="price-box">
                                                    <h5>{item.time}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !item.ticketLink ||  item.ticketLink == "" ?
                                        <h6 className='text-color-1'>Buy Tickets @ Door</h6> : 
                                        <a href={item.ticketLink} className="sc-button loadmore style bag fl-button pri-3"><span>Buy Tickets</span></a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ShowDetails;
