const menus = [
    {
        id: 1,
        name: 'Shows',
        links: '/comedy-shows',
    },
    {
        id: 2,
        name: 'Open Mics',
        links: '/open-mics'
    }
    // ,{
    //     id: 3,
    //     name: 'Local Podcasts',
    //     links: '/podcasts'
    // }    
]

export default menus;