import React , { useState , Fragment, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar'
import { format, compareDesc } from 'date-fns'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4';

const CurrentShows = props => {

    
    const [progress,setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [data, setItems] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setProgress(40)
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/1iFSfc1HY7PwK9tFOKi0HIp_wdeQPjA1OuY9YJc75EiU/values/Sheet1?key=AIzaSyDA-pldYo3BKr9eTp3FxN-ZIjSei3Uqa88`)
        .then(result=>result.json())
        .then((items)=>{
            setProgress(80)
            var keys = items.values[0];
            items.values.splice(0,1);
            var results = [];
            items.values.forEach((values, index) => {
                var newObj = {id: index};
                values.forEach((value, i) =>{
                    newObj[keys[i]] = value    
                }
                );
                results.push(newObj)
            }          
            )
         
            console.log(results)
            setProgress(100)
            setItems(results);
            setIsLoaded(true);
        },
        (error) => {
            setProgress(100)
            setIsLoaded(true);
            setError(error);
          }
    )
    ReactGA.send({ hitType: "pageview", page: "/comedy-shows" });
    }, [])
    if(isLoaded){
        return (
            <Fragment>
                <section className="tf-section today-pick">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading-live-auctions mg-bt-21">
                                    <h2 className="tf-title">
                                        Find a Show
                                    </h2>
                                </div>
                            <div className="heading-live-auctions mg-bt-21">
                                <h5>
                                    Top Comedy Venues & Show Runners in the Tampa Bay area.
                                </h5>
                            </div>
                            </div>
                            {
                                data.sort((a, b) => a.date.localeCompare(b.date)).map((item,index) => (
                                    <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                        <div className={`sc-card-product ${item.feature ? 'comingsoon' : '' } `}>
                                            {/* <h5 className='show-date-header'>{ item.date != "" ? format(new Date(item.date + " " + item.time), 'E, MMM d') : "" }</h5> */}
                                            <div className="card-media">
                                                <a href={item.ticketLink}><img 
                                                src={item.image} 
                                                alt="show image"
                                                onError={event =>{
                                                    event.target.src = "../../../assets/images/default-comedy-show.png"
                                                    event.onerror = null
                                                  }
                                                }/></a>
                                                {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                                                <div className="coming-soon">{item.feature}</div>
                                            </div>
                                            <div className="card-title">
                                                <h5 className="style2"><a href={item.ticketLink}>{item.name}</a></h5>
                                            </div>
                                            <div className="card-details">
                                                <p>{item.notes}</p>
                                            </div>
                                            <div className="meta-info">
                                                <div className="author">
                                                    {/* <div className="avatar">
                                                        <img src={item.imgAuthor} alt="axies" />
                                                    </div> */}
                                                    <div className="info">
                                                        <span>Address:</span>
                                                        <h6><a href={"https://maps.google.com/?q=" + item.address}>{item.address}</a></h6>
                                                    </div>
                                                </div>
                                                <div className="price">
                                                    {/* <span>Ticket Price:</span>
                                                    <h5>{item.price == "0.00" ? "Free" : "$" + item.price}</h5> */}
                                                </div>
                                            </div>
                                            <div className="card-bottom">
                                                {/* {
                                                    !item.ticketLink ||  item.ticketLink == "" ?
                                                    <h6 className='text-color-1'>Buy Tickets @ Door</h6> : 
                                                    <a href={item.ticketLink} className="sc-button style bag fl-button pri-3 no-bg"><span>Buy Tickets</span></a>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
    else if(!isLoaded){
        return(
            <Fragment>
                <LoadingBar height={5} loaderSpeed={1000} color='#5142FC' progress={progress} onLoaderFinished={() => setProgress(0)} />
                <div className="tf-section sc-explore-2 open-mics">
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-12">
                            <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        <div className="tf-section sc-explore-2 open-mics">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                    <h2>Error loading Upcoming Shows. Try to Refresh.</h2>
                    </div>
                </div>
            </div>
        </div>
    }
    
}

export default CurrentShows;
