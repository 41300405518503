import Shows from "./Shows";
import Explore01 from "./Explore01";
import OpenMicsPage from "./OpenMicsPage";
import ShowDetails from "./ShowDetails";
import NoResult from "./NoResult";


const routes = [
  { path: '/', component: <Shows />},
  { path: '/comedy-shows', component: <Shows />},
  { path: '/podcasts', component: <Explore01 />},
  { path: '/open-mics', component: <OpenMicsPage />},
  { path: '/show-details', component: <ShowDetails />},
  { path: '/no-result', component: <NoResult />}
]

export default routes;