import React , { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Comics = () => {

    const [error, setError] = useState(null);
    const [data, setItems] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetch(`https://sheets.googleapis.com/v4/spreadsheets/1_1wMXsjaIUOaGKJIBqW_kray0A1BJ1vQHLpj_zpVT-Q/values/Sheet1?key=AIzaSyDA-pldYo3BKr9eTp3FxN-ZIjSei3Uqa88`)
        .then(result=>result.json())
        .then((items)=>{
            var keys = items.values[0];
            items.values.splice(0,1);
            var results = [];
            items.values.forEach((values, index) => {
                var newObj = {id: index};
                values.forEach((value, i) =>{
                    newObj[keys[i]] = value    
                }
                );
                results.push(newObj)
            }          
            )
         
            console.log(results)
            setItems(results);
            setIsLoaded(true);
        },
        (error) => {
            setIsLoaded(true);
            setError(error);
          }
    )
    }, [])

    if(isLoaded){
        return (
            <div>
                <section className="tf-section top-seller home5 s2 mobie-style bg-style2">
                    <div className="themesflat-container">
                        <div className="row">
                            
                                <h2 className="col-md-12 tf-title style2 mb-25 text-left">Local Comics to Watch</h2>
                                
                                <div className="col-md-12 box-epxlore">
                                    {
                                        data.map((item,index) =>(
                                                <div key={index} className="box-item">
                                                <a href={item.social}>
                                                <div className="sc-author-box style-3">
                                                    <div className="author-avatar">
                                                            <img src={item.image} alt="Axies" className="avatar" />
                                                        <div className="badge"><i className="ripple"></i></div>
                                                    </div>
                                                    <div className="author-infor">
                                                        <h5>{item.name}</h5>
                                                        <span className="price">{item.details}</span>
                                                    </div>
                                                </div>
                                                </a>
                                            </div> 
                                        ))
                                    }
                            </div>
                        </div>
                    </div>     
                </section>
            </div>
        );
    }else if(!isLoaded){
        return(
            <div>
                <section className="tf-section top-seller home5 s2 mobie-style bg-style2">
                    <div className="themesflat-container">
                        <div className="row">
                        <h2>Loading...</h2>
                        </div>
                    </div>
                </section>
            </div>
        );
    } else {
        <div>
            <section className="tf-section top-seller home5 s2 mobie-style bg-style2">
                <div className="themesflat-container">
                    <div className="row">
                    <h2>Error loading Local Comics. Try to Refresh.</h2>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default Comics;
