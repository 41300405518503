import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Comics from '../components/layouts/home-5/Comics';
import CurrentShows from '../components/layouts/home-5/CurrentShows';
import todayPickData from '../assets/fake-data/data-today-pick';



const Shows = () => {
    return (
        <div className='home-5'>
            <Header />
            <section className="flat-title-page inner comedy-show">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <CurrentShows data={todayPickData} />
            <Comics />
            <Footer />
        </div>
    );
}

export default Shows;
